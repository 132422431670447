import { ServiceInfoCardChildInterface } from "../service-info-card-interface"

const egyediWsDesign1 : ServiceInfoCardChildInterface = {
  bigImg: false,
  boldDescription: "Olyan világban élünk, ahol a weboldalak megjelenése kiemelten fontos. A látogató az első 5 másodpercben eldönti, hogy tetszik-e neki a dizájn és az oldalon marad vagy sem",
  description: "Ezt a döntést felhasználóid mindig a dizájn alapján hozzák meg, minden funkcionalitás csak ez után következik",
  displayXo: true,
  reverse: false,
  title: "5 másodperc",
  picture: "/images/services/design_card_1.jpg"
}

const egyediWsDesign2 : ServiceInfoCardChildInterface = {
  bigImg: false,
  boldDescription: "Minél szebb, minél vonzóbb, minél letisztultabb egy felhasználói felület, a vásárló annál szívesebben tölt el időt a weboldalon és nagyobb valószínűséggel tér vissza, illetve vásárol\n",
  description: "Alapelvünk az átláthatóság és a reszponzivitás, hogy különböző felbontásokon (tableten és mobiltelefonon is) tökéletesen optimalizáltan jelenjen meg webshopod",
  displayXo: false,
  reverse: true,
  title: "Vonzó megjelenés",
  picture: "/images/services/design_card_2.jpg"
}

export const egyediWebshopDesignInfoCards = [
  egyediWsDesign1,
  egyediWsDesign2
]
