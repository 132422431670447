import { Service } from "../service-interface"
import { egyediWebshopDesignInfoCards } from "../../service-info-card-interface/used_by/egyedi-webshop-design-cards"

export const egyediWebshopDesign : Service = {
  title: 'Egyedi webshop dizájn',
  description: "Az első dolog ami megragadja látogatóid figyelmét, az egyedi dizájnod. Nézz körül milyen dizájnok tetszenek, engedd szabadon a fantáziádat és mi megvalósítjuk neked.",
  icon: "/images/pencil.svg",
  topimg: "/images/webshop_design.png",
  cards: egyediWebshopDesignInfoCards,
  listPoints: [
    "Tűnjön ki a tömegből a minőségi felhasználói megjelenéssel",
  ]
}
