import * as React from "react"
import Layout from "../components/layout"
import ServiceTop from "../components/service-page/service-top"
import SkillsSlider from "../components/skills-slider/skills-slider"
import ServiceInfoCards from "../components/service-page/service-info-cards"
import { egyediWebshopDesign } from "../interfaces/service/services/egyedi-webshop-design"
import MetaData from "../components/metadata"
import StructuredData from "../components/structured-data"
import PageBottom from "../components/page-bottom/page-bottom"

const EgyediWebshopDezijnPage = (props) => {
  return <Layout subHeader={false}>
    <MetaData title={'Egyedi webshop dizájn'} url={props.location.href} description={"Az első dolog ami megragadja látogatóid figyelmét, az egyedi dizájnod. Engedd szabadon a fantáziádat és mi megvalósítjuk neked."}/>
    <StructuredData title={'Egyedi webshop dizájn'} url={props.location.href} description={"Az első dolog ami megragadja látogatóid figyelmét, az egyedi dizájnod. Engedd szabadon a fantáziádat és mi megvalósítjuk neked."}/>
    <ServiceTop service={egyediWebshopDesign} />
    <SkillsSlider />
    <ServiceInfoCards infoCards={egyediWebshopDesign.cards} />
    <PageBottom/>
  </Layout>
}

export default EgyediWebshopDezijnPage
